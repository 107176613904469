export const logoVK =
    <>
        <path xmlns="http://www.w3.org/2000/svg" fill="#5181B8" d="M66.56 0h58.88C179.2 0 192 12.8 192 66.56v58.88c0 53.76-12.8 66.56-66.56 66.56H66.56C12.8 192 0 179.2 0 125.44V66.56C0 12.8 12.8 0 66.56 0z" />
        <path xmlns="http://www.w3.org/2000/svg" fill="#FFF" d="M157.234 66.14622c.88956-2.96645 0-5.14622-4.23376-5.14622h-14c-3.55973 0-5.20082 1.883-6.09088 3.9594 0 0-7.11948 17.35354-17.20516 28.6258-3.26297 3.26297-4.74632 4.30116-6.5262 4.30116-.8898 0-2.178-1.0382-2.178-4.0044V66.14623C107 62.58648 105.96694 61 103.00024 61h-22c-2.22452 0-3.56237 1.65216-3.56237 3.21797 0 3.37456 5.04259 4.15278 5.56237 13.64542V98.4799c0 4.5201-.8163 5.3396-2.59617 5.3396-4.74607 0-16.2909-17.43106-23.13806-37.37676C55.92414 62.566 54.57825 61 51.00024 61h-14c-4 0-4.80004 1.883-4.80004 3.9594 0 3.70812 4.74631 22.09986 22.09961 46.42425C65.86859 127.99527 82.16824 137 97.00024 137c8.8991 0 10-2 10-5.445V119c0-4 .84305-4.79829 3.66112-4.79829 2.07639 0 5.63613 1.0382 13.94218 9.04749 9.4924 9.49239 11.05734 13.7508 16.3967 13.7508h14c4 0 6-2 4.84624-5.94689-1.26257-3.9337-5.79468-9.64097-11.80836-16.4065-3.26321-3.85625-8.15767-8.00904-9.64102-10.08592-2.07639-2.66943-1.4831-3.85626 0-6.22917 0 0 17.05702-24.02787 18.8369-32.1853z" />
    </>

export const logoFH2 =
    <>
        <path xmlns="http://www.w3.org/2000/svg" fill='#024c3a' d="M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0 -1125z m1688 493 l-3 -213 -348 0 -347 0 0 -492 0 -493 -215 0 -215 0 0 705 0 705 565 0 565 0 -2 -212z m-138 -563 l0 -215 -215 0 -215 0 0 208 c0 115 3 212 7 215 3 4 100 7 215 7 l208 0 0 -215z" />
    </>

export const logoFH =
    <>
        <path xmlns="http://www.w3.org/2000/svg" fill='#024c3a' d="M26 1473 c-3 -444 -8 -950 -10 -1124 l-4 -317 806 -6 c444 -3 949 -8 1123 -10 l317 -4 6 491 c3 271 8 776 11 1123 l6 632 -493 6 c-271 3 -777 8 -1124 11 l-632 6 -6 -808z m1686 369 c4 -4 5 -102 1 -216 l-6 -209 -349 6 -348 5 0 -222 c0 -123 -3 -345 -7 -494 l-6 -272 -147 0 c-81 0 -175 3 -209 6 l-61 7 0 486 c0 267 3 583 7 701 l6 215 556 -3 c306 -2 559 -7 563 -10z m-142 -767 l0 -215 -212 2 -213 3 -3 200 c-1 110 0 205 2 212 4 10 54 13 216 13 l210 0 0 -215z" />
    </>