//data
export const SET_HOUSE_PUBLIC = 'SET_HOUSE_PUBLIC';
export const UPDATE_MAIN_ATRIBUTES_HOUSE = 'UPDATE_MAIN_ATRIBUTES_HOUSE';
export const UPDATE_MATERIAL_ATRIBUTES_HOUSE = 'UPDATE_MATERIAL_ATRIBUTES_HOUSE';
export const SET_HOUSE_FOR_EDIT = 'SET_HOUSE_FOR_EDIT';
export const SET_IMAGE_WAIT = 'SET_IMAGE_WAIT';
export const SET_IMAGE_DELETE = 'SET_IMAGE_DELETE';
export const SET_HOUSES = 'SET_HOUSES'
export const PAGINATION_COUNT_INCREMENT = 'PAGINATION_COUNT_INCREMENT'
export const PUSH_SNAPSHOT = 'PUSH_SNAPSHOT';
export const RESET_SNAPSHOT_AND_PAGINATION = 'RESET_SNAPSHOT_AND_PAGINATION';
export const SET_PAGINATION_PAGE = 'SET_PAGINATION_PAGE'
export const SET_PAGINATION_COUNT = 'SET_PAGINATION_COUNT'

//uiData
export const SET_MOBILE_OPEN = 'SET_MOBILE_OPEN';
export const SET_MOBILE_OPEN_FALSE = "SET_MOBILE_OPEN_FALSE"
export const SET_SIGNED = 'SET_SIGNED';
export const SET_UN_SIGNED = 'SET_UN_SIGNED';
export const SET_PROFILE_FIRST_TIME = 'SET_PROFILE_FIRST_TIME';
export const SET_ALERT_ON = 'SET_ALERT_ON';
export const SET_ALERT_OFF = 'SET_ALERT_OFF';
export const SET_BACKDROP_ON = 'SET_BACKDROP_ON';
export const SET_BACKDROP_OFF = 'SET_BACKDROP_OFF';
export const SET_LINEAR_ON = 'SET_LINEAR_ON';
export const SET_LINEAR_OFF = 'SET_LINEAR_OFF';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_PAY_VIEW = 'SET_PAY_VIEW';
export const SET_CONFIG = 'SET_CONFIG'
export const PUSH_MY_PURCHASES = 'PUSH_MY_PURCHASES'
export const UPDATE_MY_PURCHASE = 'UPDATE_MY_PURCHASE'
export const DELETE_MY_PURCHASE = 'DELETE_MY_PURCHASE'
//userData
export const SET_USER_PRIVATE_PROFILE = "SET_USER_PRIVATE_PROFILE";
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_HOUSES_USER = 'SET_HOUSES_USER'
export const PUSH_HOUSE_HOUSES_USER = 'PUSH_HOUSE_HOUSES_USER';
export const SET_HOUSE_PUBLIC_USER = 'SET_HOUSE_PUBLIC_USER';
export const SET_HOUSE_PRIVATE_USER = 'SET_HOUSE_PRIVATE_USER';
export const UPDATE_PAY_ATRIBUTES_HOUSE = 'UPDATE_PAY_ATRIBUTES_HOUSE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PRIVATE_PROFILE = 'UPDATE_USER_PRIVATE_PROFILE';
export const SET_PAGINATION_PAGE_USER = "SET_PAGINATION_PAGE_USER";
export const PUSH_SNAPSHOT_USER = "PUSH_SNAPSHOT_USER";
export const SET_PAGINATION_COUNT_USER = "SET_PAGINATION_COUNT_USER"
export const RESET_SNAPSHOT_AND_PAGINATION_USER = "RESET_SNAPSHOT_AND_PAGINATION_USER"
export const SET_CURRENT_PROFILE_ID = "SET_CURRENT_PROFILE_ID"

//TODO check 
